let feedbackHelpful = null

document.querySelectorAll('.feedback .thumbs button').forEach((btn) => {
  btn.addEventListener('click', (e) => {
    feedbackHelpful = btn.getAttribute('data-value') === 'yes'

    // swap out form label text
    document.querySelector('.feedback-label').textContent =
      feedbackHelpful ? 'What was most helpful?' : 'What could be improved?'

    // show form
    document.querySelector('.feedback .thumbs').classList.add('hide')
    document.querySelector('.feedback-form').classList.remove('hide')
  })
})

const feedbackForm = document.querySelector('.feedback-form')

feedbackForm && feedbackForm.addEventListener('submit', function (e) {
  e.preventDefault()

  // don't submit if a thumb button was never clicked
  if (feedbackHelpful === null) {
    return false
  }

  const formData = new FormData(this)
  const email = formData.get('email')
  const pageTitle = document.title.split(' ::')[0]

  // format zendesk request
  const feedback = {
    request: {
      requester: {
        name: 'Doc User',
      },
      subject: `Documentation: ${pageTitle} page ${feedbackHelpful ? 'is' : 'IS NOT'} helpful`,
      comment: {
        body: formData.get('comment'),
      },
    },
  }

  if (email) {
    feedback.request.requester.email = email
  }

  fetch('https://greenbird.zendesk.com/api/v2/requests.json', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(feedback),
  })

  // show thank you message
  document.querySelector('.feedback-form').classList.add('hide')
  document.querySelector('.feedback .confirmation').classList.remove('hide')

  feedbackHelpful = null
})
