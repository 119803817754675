let editorWindow
let payloadEditor
let queryEditor

function openEditorWindow (payload, query) {
  // create a modal window
  editorWindow = document.createElement('div')
  editorWindow.className = 'modal editor-window'
  editorWindow.innerHTML = `
    <span role="button" aria-label="Close" class="close cursor">&times;</span>
    <div class="content">
      <div class="left">
        <button class="run-btn"></button>
      </div>
      <div class="right">
        <strong class="header">Payload:</strong>
        <div class="payload-editor editor"></div>
    
        <strong class="header">Mapping:</strong>
        <div class="mapping-editor editor"></div>
    
        <strong class="header">Output:</strong>
        <pre class="output editor"></pre>
      </div>
    </div>
  `

  document.body.appendChild(editorWindow)

  // add click events for modal buttons
  editorWindow.querySelector('.run-btn').addEventListener('click', runQuery)
  editorWindow.querySelector('.close').addEventListener('click', closeEditorWindow)
  editorWindow.addEventListener('click', (event) => {
    if (event.target.classList.contains('modal')) {
      closeEditorWindow()
    }
  })

  document.addEventListener('keydown', handleEscape)

  // initialize the codemirror editors
  payloadEditor = window.createCodeEditor(payload, editorWindow.querySelector('.payload-editor'), true)
  queryEditor = window.createCodeEditor(query, editorWindow.querySelector('.mapping-editor'))
}

function handleEscape (event) {
  if (event.key === 'Escape') {
    closeEditorWindow()
  }
}

function closeEditorWindow () {
  document.body.removeChild(editorWindow)
  document.removeEventListener('keydown', handleEscape)

  payloadEditor = null
  queryEditor = null
  editorWindow = null
}

function runQuery () {
  // show loading animation
  const btnEl = document.querySelector('.run-btn')
  btnEl.classList.add('loading')

  let apiUrl = 'https://api.test.utilihive.io/jsoniq-demo/api/map'
  const payload = encodeURIComponent(payloadEditor.state.doc.toString())
  const query = queryEditor.state.doc.toString()

  if (payload) {
    apiUrl += `?payload=${payload}`
  }

  // make request to live jsoniq server
  fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
    },
    body: query,
  })
    .then(async (response) => {
      const responseData = await response.json()
      let results = ''

      // strip out error message from server
      if (!response.ok && responseData.message) {
        results = responseData.message.replace(/^com\..*: /, '')

        // format forced failed messages
        if (response.status === 400) {
          results = 'IllegalArgumentException: ' + results
        }
      } else {
        results = JSON.stringify(responseData, null, 2)
      }

      // display results with syntax highlighting
      document.querySelector('.output').innerHTML = `<code data-lang="json">${results}</code>`
      window.hljs.highlightBlock(document.querySelector('.output code'))
    })
    .catch((error) => {
      document.querySelector('.output').innerHTML = `<code class='error'>${error}</code>`
    })
    .finally(() => {
      btnEl.classList.remove('loading')
    })
}

// find every jsoniq mapper example on page
document.querySelectorAll('.live-editor').forEach((block) => {
  const query = block.querySelector('code').textContent.trim()
  let payload = ''

  // grab payload example from hidden next element, if it exists
  if (block.nextElementSibling.classList.contains('payload')) {
    payload = block.nextElementSibling.textContent.trim()
    block.parentNode.removeChild(block.nextElementSibling)
  }

  // create the "try it" button
  const tryButton = document.createElement('button')
  tryButton.className = 'try-btn'
  tryButton.textContent = 'Try It'
  tryButton.addEventListener('click', () => openEditorWindow(payload, query))

  block.parentNode.insertBefore(tryButton, block.nextSibling)
})
